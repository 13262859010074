<template>
  <div>
    <div class="routes-text">
      Маршруты через остановку
    </div>

    <div class="routes" ref="routes">
      <div class="routes__block" :class="{marquee: isScrollAuto}" ref="block">
        <div
            v-for="item in currentRoutesSet"
            :key="item.id"
            class="route"
        >
          <img class="route__img" src="@/assets/img/busIcon.svg">
          <p class="route__name-route">
            {{ item.number }}
          </p>
        </div>
      </div>
      <div aria-hidden="true" class="routes__block" :class="{marquee: isScrollAuto}" >
        <div
            v-for="item in currentRoutesSet"
            :key="item.id"
            class="route"
        >
          <img class="route__img" src="@/assets/img/busIcon.svg">
          <p class="route__name-route">
            {{ item.number }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoutesThroughStation",

  mounted() {
    setInterval(() => {
      this.checkAutoScroll()
    }, 100)
  },

  data: () => ({
    isScrollAuto: false
  }),

  props: {
    stopProps: {
      type: [Array, Object],
      default: () => [],
    },
  },
  methods: {
    checkAutoScroll () {
      if (this.$refs.block) this.isScrollAuto = false
      const children = this.$refs.block.children
      let widthChildren = 0

      for(const child of children) {
        widthChildren += child.clientWidth
      }

      this.isScrollAuto = this.$refs.routes.clientWidth < widthChildren
    }
  },
  computed: {
    currentRoutesSet: function() {
      return this.stopProps
      // if (this.stopProps.length === 0) {return}
      //
      // const fullRoutesSet = [...this.stopProps.direct_routes, ...this.stopProps.backward_routes];
      // const uniqueRoutesSet = Array.from(new Set(fullRoutesSet.map(a => a.id)))
      //     .map(id => {
      //       return fullRoutesSet.find(a => a.id === id);
      //     });
      //
      // return uniqueRoutesSet;
    }
  }
}
</script>

<style lang="scss" scoped>
.routes-text {
  font-size: 30px;
  font-weight: normal;
  line-height: 50px;
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, 0.7);

  padding: 0 32px 12px;
}

.routes {
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;

  &:before,
  &:after {
    content: "";
    height: 100%;
    top: 0;
    width: 10%;
    position: absolute;
    z-index: 1;
    pointer-events: none;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }

  &__block{
    position: relative;
    flex-shrink: 0;
    display: flex;
    min-width: 100%;
    padding: 0 32px 32px;
    &.marquee{
      animation: scroll 20s linear infinite;
      &:after{
        content: "";
        position: absolute;
        right: 8px;
        height: 84px;
        width: 1px;
        background-color: #cccccc;
      }
    }
  }

  .route {
    display: flex;
    align-items: center;
    width: auto;
    padding: 16px;
    background: #3A3A3C;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.08), 0px 6px 10px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    opacity: 0.9;
    margin-right: 16px;

    &__img {
      padding: 12px;
      margin-right: 16px;
      background: #0B83FF;
      border-radius: 100px;
    }

    &__name-route {
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 35px;
      letter-spacing: -0.5px;
      color: #fff;
    }
  }
}


@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100%));
  }
}
</style>
